<template>
  <DSidebarView title="Explore" color="pink" :items="subNavigation" />
</template>

<script setup>
import { TicketIcon } from '@heroicons/vue/solid'
import { DHHIcon, GhostIcon } from '@ui'
import DSidebarView from '@/components/ui/layout/DSidebarView.vue'

const subNavigation = [
  {
    name: 'Diamond Hands Originals',
    description: 'Bespoke NFT collections created by Diamond Hands Hotel.',
    to: { name: 'ExploreDrops' },
    exact: true,
    icon: DHHIcon
  },
  {
    name: 'Stealth Drops',
    description: 'Highly limited collections only available to Originals hodlers.',
    to: { name: 'ExploreStealth' },
    icon: GhostIcon,
    contained: false,
  },
  {
    name: 'Guest Pass',
    description: 'Hand-picked projects with special deals for our hotel guests.',
    to: { name: 'ExploreGuest' },
    icon: TicketIcon
  },
]


import { DSidebarLayout } from '@ui'
import { listDrops } from '@/gql/drops'

import HeroCard from '@/components/roof/drops/HeroCard.vue'
import AppLink from '@/components/roof/AppLink.vue'
import SidebarExplore from './SidebarExplore.vue'
</script>