import gql from 'graphql-tag'

export const dropDatasheet = gql`query dropDatasheet ($id: uuid!) {
  drop: drops_by_pk(id: $id) {
    id
    name
    description
    website
    symbol
    stats {
      totalSupply
      numOwners
      floorPrice
      totalVolume
      weekAvgPrice
      monthAvgPrice
    }
  }
}`

export const listDrops = gql`query listDrops ($where: drops_bool_exp, $order: [drops_order_by!], $limit: Int, $offset: Int) {
  drops(where: $where, order_by: $order, limit: $limit, offset: $offset) {
    id
    status
    slug
    name
    description
    symbol
    dropId
    supply
    coverImg
    creator {
      id
      name
      avatarImg
    }
  }
}`

export const dropBySlug = gql`query dropBySlug ($slug: String!) {
  drops(where: { slug: { _eq: $slug } }, limit: 1) {
    id
    status
    slug
    name
    description
    symbol
    dropId
    revealDate
    supply
    avatarImg
    coverImg
    website
    twitter
    discord
    alertLabel
    alertDescription
    alertUrl
    chain {
      name
      humanName
    }
    customization {
      primaryColor
      secondaryColor
      gradient
    }
    presale {
      tokenPrice
      tokenLimit
      startDate
      endDate
      availableSpots
    }
    sale {
      tokenPrice
      tokenLimit
      startDate
      endDate
    }
  }
}`